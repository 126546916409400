.test {
  background-color: red;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.head-sec {
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: scroll;
  text-align: center;
  margin: 0 auto !important;
}
.wallet-button {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: inherit;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #213550;
  background-color: #e0e8ef;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: rgba(255, 255, 255, 0.333);
  border-radius: 40px 40px 40px 40px;
  padding: 16px 24px 16px 28px;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
}

.wallet-button:hover {
  color: #516d91;
  background-color: #e5edf5;
  box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%);
}
.head {
  text-align: left;
}

.btn-1 {
  background-color: aliceblue;
  padding: 10px 40px;
  border: 1px solid transparent;
  border-radius: 10px;
  font-size: 18px;
}
.btn-1:hover {
  background-color: transparent;
  padding: 10px 40px;
  border: 1px solid aliceblue;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  transition: 0.4s ease-out;
  font-weight: 700;
}

.banner-sec {
  background-color: #050a1e;
  background-position: initial;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  margin: auto;
}

.top-header-shape:before {
  content: " ";
  background-image: url("/public/hero-shape.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 450px;
  height: 350px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  animation: top-shape 2s infinite ease-out;
}
@keyframes top-shape {
  0% {
    top: 0px;
    left: -20px;
  }
  50% {
    top: 0px;
    left: 0px;
  }
  100% {
    top: 0px;
    left: -20px;
  }
}

.top-header-shape:after {
  content: " ";
  background-image: url("/public/hero-shape_2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 320px;
  height: 320px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.7;
  animation: top-shape2 2s infinite ease-out;
}
@keyframes top-shape2 {
  0% {
    top: 0px;
    left: -20px;
  }
  50% {
    top: 0px;
    left: -40px;
  }
  100% {
    top: 0px;
    left: -20px;
  }
}

.join-social {
  display: flex;
  position: relative;
  align-items: center;
  /* justify-content: space-between; Spacing between the elements */
  /* background-color: #333; */
  transition: background-color 0.3s ease;
  padding: 10px;
}
.join-com {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
}

.social-icons {
  display: none;
  display: flex; /* Flex container for the icons */
  flex-wrap: wrap;
  align-items: center;
  margin-left: 50px;
  justify-content: space-between;
}
.social-icon-single {
  z-index: 5;
  cursor: pointer;
  transition: color 0.3s ease;
}

.join-social.active .join-com {
  width: 100px; /* Adjust the width as needed for the full text */
}

.join-social.active .social-icons {
  display: flex;
}
.social-icons .svg-inline--fa {
  font-size: 25px;
  margin-right: 10px; /* Adjust the margin value as needed */
}

.social-icons i {
  margin-right: 10px;
  font-size: 20px;
  background-color: #000000;
  color: white; /* Add a color to make the icon visible on the background */
  border-radius: 50%; /* Make the icon circular by setting border-radius to 50% */
  padding: 10px; /* Add some padding to give space around the circular icon */
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: var(--width);
  transform: var(--transform);
}

.imges-gap {
  margin: 0px 10px;
}

.img-bg {
  background-color: transparent;
}

.token-main-container {
  display: flex;
  gap: 0px 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.token-select {
  background-color: lightgray;
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 12px 15px;
  gap: 8px;
  border: none;
  transition: transform 0.3s ease-in-out; /* Add a transition to the transform property */
}
.image_wrapper {
  margin: 0px 30px;
  width: auto;
  /* height: 7rem; */
  display: flex;
}
.initial-child-container .child {
  display: flex;
}

.token-select.active {
  background-color: #323232d9; /* Active background color (black) */
  color: #fff; /* Active text color (white) */
}
.content-sec p {
  color: #fff;
}

.content-sec h3 {
  color: #fff;
}

.content-sec h5 {
  color: #fff;
}

.content-sec h2 {
  color: #fff;
}

.content-sec h4 {
  color: #fff;
}

.content-sec2 p {
  color: #808080;
}

.content-sec2 h3 {
  color: #000000;
}

.content-sec2 h5 {
  color: #000000;
}

.content-sec2 h2 {
  color: #000000;
}

.content-sec2 h4 {
  color: #000000;
}
.scroll-container {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  /* Add other styling as needed */
}

.scroll-item {
  display: inline-block;
  /* Define item width and other styles */
}
.token {
  background-color: #000;
  font-weight: bold;
  padding: 10px 20px;
  width: 500px !important;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.token:hover {
  background-color: #fff;
  color: black;
}
.token-text {
  margin-top: 22px;
}

/* Amount Box */

.amount-box {
  margin: 20px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* align-items: center; */
  text-align: start;
}

.bottom-border {
  outline: none;
  border: none;
  border-bottom: 2px solid #adacac;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}
.enter-amount label,
.token-cong label {
  display: block; /* Make the labels display as blocks */
  margin-bottom: 5px; /* Add some space between the label and the input */
}

.price-per {
  text-align: end;
}
.sale-supply .price-per {
  font-size: 1.1rem;
}

.right-banner {
  background-color: #f5f5f5;
  margin-bottom: 50px;
}

/* Connect Now Part */

.connect-now {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.connect-now-text {
  text-align: center;
  width: 330px;
  align-items: center;
}
.connect-now-button {
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  width: 180px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  margin-bottom: 20px;
}
.connect-now-button:hover {
  background-color: white;
  color: black;
}

/* Partner Container */

.partner-container {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 15%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0.7) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  /* background-color: #f0f0f0; */
  padding: 10px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.partner img {
  margin-right: 40px; /* Increase the margin between images */
}

.partner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  animation: marquee 10s linear infinite; /* Change the animation duration as needed */
}
.partner.paused {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-100% + 10px)
    ); /* Adjust the translation distance as needed */
  }
}
.part1 {
  padding: 1rem 2rem !important;
  margin: 1rem;
}

@media screen and (max-width: 768px) {
  .top-header-shape:after {
    display: none;
  }
  .part1 {
    order: 2;
    padding: 0 !important;
    margin-top: 2rem !important;
  }
  .part2 {
    order: 1;
  }
  .top-header-shape:before {
    display: none;
  }
  .price-per {
    text-align: end;
  }
  .sale-supply .price-per {
    font-size: 1.1rem;
  }
  .connect-now-button {
    margin-bottom: 20px;
  }
  .right-banner {
    margin-bottom: 20px;
  }
  
  .partner-container {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .connect-now-text {
    width: 180px;
    /* align-items: center; */
  }
  .connect-now-button {
    margin-bottom: 20px;
  }
  .partner-container {
    margin-bottom: 50px;
  }
  .right-banner {
    margin-bottom: 20px;
  }
}

.about-section-title h5 {
  width: auto;
  font-size: 18px;
  text-transform: capitalize;
  color: white;
  margin: 0 0 16px;
  position: relative;
  margin-left: 53px;
  display: inline-block;
}
.about-section-title p {
  font-size: 17px;
  color: #b6b9c0;
  margin-top: 15px;
}

.about-section-title h5:before {
  position: absolute;
  padding: inherit;
  content: "";
  top: 10px;
  left: -43px;
  height: 2px;
  width: 41px;
  background: #ff3c00;
}
.about-section-title h2 {
  font-style: normal;
  transition: 0.5s;
  font-weight: 900;
}

/* about section horizontal animated line */

/***
======================================================
<--  Consen Lines CSS -->
======================================================***/
.lines .line {
  position: relative;
  width: 93%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  z-index: 5;
}

.lines .line::after {
  content: "";
  display: block;
  position: relative;
  height: 1px;
  width: 35px;
  top: 0;
  left: 0px;
  background-color: #ff3b00;
  -webkit-animation: moveLeftBounces-two 35s linear infinite;
  animation: moveLeftBounces-two 35s linear infinite;
  overflow: hidden;
}

.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes moveLeftBounces-two {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(585px);
    transform: translateY(585px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes moveLeftBounces-two {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(585px);
    transform: translateX(585px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.join-community {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.text {
  color: white;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

.icons {
  position: absolute;
  top: 25%;
  right: -14rem; /* Initially hidden, positioned over text */
  display: flex;
  align-items: center;
  opacity: 0;
  animation: linear infinite;
  transform: translateX(-100%); /* Initially off-screen to the left */
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.join-community:hover .icons {
  transform: translateX(0);
  opacity: 1;
}

.banner-buttons {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}
.slider-contact-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
/*slider button*/
.slider-button span {
  text-decoration: none;
  padding: 11px 20px 11px 30px;
  font-weight: 500;
  align-items: center;
  display: flex;
  border-radius: 30px;
  border: 1px solid #ff3b00;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.slider-button span:before {
  position: absolute;
  content: "";
  z-index: -1;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: #fff;
  border-radius: 30px;
  transition: 0.5s;
}

.slider-button span i {
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 25px;
  text-align: center;
  /* background: #fff;
  color: #FF3B00; */
  display: inline-block;
  margin-left: 14px;
  position: relative;
  transition: 0.5s;
}

/* .slider-button span:hover {
  color: #FF3B00;
} */

.slider-button span:hover:before {
  width: 100%;
  left: 0;
}

/* plus minus toggle animation */

/* PlusMinusToggle.css */
.plus-minus-toggle {
  cursor: pointer;
  height: 13px;
  margin-right: 3px;
  position: relative;
  width: 13px;
}

.plus-minus-toggle:before,
.plus-minus-toggle:after {
  background: #ff3b00;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 16px;
  transition: transform 300ms ease;
}

.plus-minus-toggle:before {
  transform: translateY(-50%) rotate(0deg);
  transform-origin: center;
}

.plus-minus-toggle:after {
  transform: translateY(-50%) rotate(90deg);
  transform-origin: center;
}

.plus-minus-toggle.collapsed:before {
  background-color: white;
  transform: translateY(-50%) rotate(180deg);
}

.plus-minus-toggle.collapsed:after {
  background-color: white;
  transform: translateY(-50%) rotate(-180deg);
}

.preSale-thumb1 {
  position: absolute;
  right: 10px;
  top: 10px;
  animation: moveLeftBounce 3s linear infinite;
}

/*left bounce animation*/

@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes MOVIE-BG {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/*form button*/
.addToken_button button {
  padding: 13px 15px;
  background: #10102e;
  display: block;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  transition: 0.5s;
}

.addToken_button button:hover {
  color: #fff;
  background: #ff3c00;
}

/* Choose Token Style */

.chooseToken-title h3 {
  font-size: 18px;
  color: #ff3c00;
  background-color: #f5f5f5;
  font-weight: 500;
  text-align: center;
  position: relative;
  z-index: 1;
  /* padding: 0 0 44px; */
}

.chooseToken-title h3:before {
  position: absolute;
  content: "";
  z-index: 0;
  left: -200px;
  top: 9px;
  height: 2px;
  width: 380px;
  background-color: rgba(35, 35, 35, 0.10196078431372549);
}

.chooseToken-title h3:after {
  position: absolute;
  content: "";
  z-index: 0;
  right: -200px;
  top: 9px;
  height: 2px;
  width: 380px;
  background-color: rgba(35, 35, 35, 0.10196078431372549);
}

/*form input*/
.form_box input {
  width: 100%;
  height: 50px;
  padding-left: 25px;
  background: #f7f4f4;
  border-radius: 30px;
  color: #232323;
  border: 1px solid rgba(35, 35, 35, 0.12);
  transition: 0.5s;
}

.form_box textarea {
  width: 100%;
  background: #f7f4f4;
  padding-left: 25px;
  padding-top: 20px;
  height: 135px;
  border: 1px solid rgba(35, 35, 35, 0.12);
  border-radius: 30px;
  color: #232323;
  transition: 0.5s;
}

.form_box input::placeholder {
  color: #6d6d6d;
}

.form_box input:focus,
.form_box textarea:focus {
  border-color: #ff3c00;
  outline: 0;
  box-shadow: 0 0 6px rgb(204, 48, 0, 0.6);
}

/* Social Icons transition */

.social-container a {
  opacity: 0;
  font-size: 28px;
  color: #1f1e1e;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 200px;
  height: 50px;
  background-color: #eeeeed;
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btn_wrap span {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  border-radius: 80px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  letter-spacing: 2px;
  color: #eeeeed;
  background-color: #ff3c00;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.social-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 64px;
  border-radius: 80px;
}

.social-container a:nth-of-type(1) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.social-container a:nth-of-type(2) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.social-container a:nth-of-type(3) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.social-container a:nth-of-type(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  -webkit-transform: translateX(-280px);
  transform: translateX(-280px);
}

.btn_wrap:hover a {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.brand-logos {
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); /* Shadow properties */
  transition: box-shadow 0.3s; /* Smooth transition for the shadow */
}

.brand-logos:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.join-community-text {
  font-family: sans-serif;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 4px;
  color: #aeaeae;
}


/*social icon*/
.follow-company-icon a {
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background: #187DFF;
  border-radius: 50px;
  display: inline-block;
  color: #fff;
  margin-right: 8px;
  position: relative;
  z-index: 1;
  transition: transform 0.5s, background-color 0.5s; /* Add transition properties */
}

.follow-company-icon a:before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #11112E;
  border-radius: 50px;
  transform: scale(0);
  transition: .5s;
}

a.social-icon-color1 {
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background: #219ff9;
  border-radius: 50px;
  display: inline-block;
  color: #fff;
  margin-right: 8px;
}

a.social-icon-color2 {
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background: linear-gradient(135deg, #b618c1 0%, #f3593d 100%);
  border-radius: 50px;
  display: inline-block;
  color: #fff;
  margin-right: 8px;
}

a.social-icon-color3 {
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background: #F70102;
  border-radius: 50px;
  display: inline-block;
  color: #fff;
  margin-right: 8px;
}
.follow-company-icon a:hover {
  transform: scale(1.1); /* Scale up the icon on hover */
  background-color: #11112E; /* Change background color on hover */
}
/* Keyframes for the bubble animation */
@keyframes bubble {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/*widget title*/
h1.social-title {
  font-weight: 600;
  position: relative;
  z-index: 1;
}

h1.social-title:before {
  position: absolute;
  content: "";
  border-radius: 10px;
  left: 0;
  top: 28px;
  background: #ff3c00;
  width: 50px;
  height: 4px;
  transition: .5s;
}