/* Arrow Animation CSS */
.arrow {
  background: #fff;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.arrow:before,
.arrow:after {
  content: "";
  background: #fff;
  position: absolute;
  height: 3px;
  width: 15px;
}

.arrow:before {
  right: -3px;
  bottom: -4px;
  transform: rotate(-45deg);
}

.arrow:after {
  right: -3px;
  top: -4px;
  transform: rotate(45deg);
}

/* Animation 1 */
#animation1 + label > .arrow:before {
  animation: animation-1-before-reverse 1s ease 0s 1 alternate both;
}

#animation1 + label > .arrow:after {
  animation: animation-1-after-reverse 1s ease 0s 1 alternate both;
}

#animation1:checked + label > .arrow:before {
  animation: animation-1-before 1s ease 0s 1 alternate both;
}

#animation1:checked + label > .arrow:after {
  animation: animation-1-after 1s ease 0s 1 alternate both;
}

@keyframes animation-1-after {
  0% {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
  }
  50% {
    width: 3px;
    border-radius: 50%;
    top: -20px;
    left: 15px;
    transform: rotate(0deg);
  }
  100% {
    left: -3px;
    top: -4px;
    transform: rotate(-45deg); /* Change to rotate(0deg) */
  }
}

@keyframes animation-1-before {
  0% {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
  }
  50% {
    width: 3px;
    border-radius: 50%;
    bottom: -20px;
    left: 15px;
    transform: rotate(0deg);
  }
  100% {
    left: -3px;
    bottom: -4px;
    transform: rotate(45deg); /* Change to rotate(0deg) */
  }
}

@keyframes animation-1-after-reverse {
  0% {
    left: -3px;
    bottom: -4px;
    transform: rotate(45deg); /* Change to rotate(0deg) */
  }
  50% {
    width: 3px;
    border-radius: 50%;
    top: -20px;
    left: 15px;
    transform: rotate(0deg);
  }
  100% {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
  }
}

@keyframes animation-1-before-reverse {
  0% {
    left: -3px;
    bottom: -4px;
    transform: rotate(-45deg); /* Change to rotate(0deg) */
  }
  50% {
    width: 3px;
    border-radius: 50%;
    bottom: -20px;
    left: 15px;
    transform: rotate(0deg);
  }
  100% {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
  }
}

/* General Styles */

.text-center {
  text-align: center;
}

